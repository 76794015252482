import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Box, Text } from 'rebass'
import LocalizedLink from '../utils/LocalizedLink'

const Storepolicy = ({ data }) => (
    <Layout>
        <article className='sheet'>
            <div className='sheet__inner'>
                <Box>
                    <Box>
                        <Box p={12} color='#40436A'>
                            <Text
                                fontSize={3}
                                dangerouslySetInnerHTML={{
                                    __html: data.storepolicy.data.title.html,
                                }}
                            />
                        </Box>
                        <Box p={12} color='#40436A'>
                            <Text
                                fontSize={2}
                                dangerouslySetInnerHTML={{
                                    __html: data.storepolicy.data.description.html,
                                }}
                            />
                            <br />
                            <LocalizedLink to={`/store`}>{'Back to store'}</LocalizedLink>
                        </Box>

                    </Box>
                </Box>
            </div>
        </article>
    </Layout>
)

export default Storepolicy

export const query = graphql`
    query StorepolicyQueryEn {
        storepolicy: prismicStorepolicy(lang: { eq: "en-us" }) {
            data {
                description {
                    text
                    html
                }
                title {
                    text
                    html
                }
            }
        }
    }
`
